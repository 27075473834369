.vendor-page{
  background: fixed !important;
  display: flex;
  min-height: 100vh;
}

#displayrow{
  justify-content: space-between !important;
  min-height: 100vh;
  padding: 5px;
  /* padding: 15px 50px; */
  position: relative !important;
}

.cartProductCard button{
 background: #9D2904;
 color:#fff !important;
}
.minus,.plus{
    height: 22px;
    color: #fff;
    padding: 0px 9px
}

.minus.disabled:disabled,
.minus.disabled:hover{
  background-color: #cccccc !important;
  color: #666666;
}
.modal-btn{
  border: 1px solid gray !important;
  color: rgba(0, 0, 0, 0.65) !important;

}

.cartProductCard .quantity{
  background: #fff !important;
  color:#000 !important; 
  height: 22px;         
  border: 0px; 
  padding: 0px 9px;
}

.resName {
  color: rgb(27, 48, 69);
  font-size: 16px !important;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cartSec{
  height: 620px;
  margin-bottom: 30px;
  overflow-Y: scroll;
}
.TableSec{
  height: 570px;
  margin-bottom: 30px;
  overflow-Y: scroll;
}
.print-box{
  margin-right: 10px;
}
.totalBillSummary .ant-descriptions-row{
  display:flex;
  flex-direction: column;
}
.totalBillSummary .ant-descriptions-item{
  display:flex;
  justify-content: space-between
}
.productDisplay{
    max-height: 800px;
    overflow-Y: scroll;
    overflow-X: hidden;
}

.btn-primary-outline {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.vendorProduct .productVendorCard{
  height: 120px;
}
.cartSec .cartProductCard .ant-card-body{
  flex-direction: column;
}
.productVendorCard img{
  /* height: 80px; */
  width: 80px;
}
.ant-card-meta-description {
  font-size: 12px !important;
}

.productCard .ant-card-body {
  width: 100%;
}

.cartProductCard .ant-card-body {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.minus:hover ,.plus:hover , .minus:active , .plus:active, .minus:focus , .plus:focus{
  background-color: #9D2904 !important;
  color: white !important;
}


.ant-pagination .ant-table-pagination {
  height: 10px !important;

}
.ant-table-row-cell-break-word {
  font-size: 20px !important;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
  font-size: 12px !important;
  justify-content: center !important;
  
}

.ant-table-row-cell-break-word .ant-table-row-cell-last {
  font-weight: 900 !important;
}
.ant-table-pagination.ant-pagination{
  padding-top: 30px;
}

.username,
.header-info-wrapper,
.menu-options,
.btn-link,
.btn-link-ok,
p,
b,
a,
td,
.ant-btn-link,
button,
.ant-input,
.ant-tag {
  font-size: 14px !important;
  letter-spacing: .4px !important;
}

.ant-table-wrapper {
  cursor: pointer;
}
.ant-table-wrapper{
  width: 100%;
}
.unitType{
  padding-left: 6px;
}
#displayrow .ant-card{
  border-radius: 5px;
}
.midSec{ 
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    border-radius: 20px;
}
.ant-card-meta-title{
  overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 15px;
    white-space: wrap !important;
    text-overflow: ellipsis;

}

.checkout-btn ,.checkout-btn:hover ,
.checkout-btn:active,
.checkout-btn:focus{
  background-color: #13CD52
  !important;
    border: none;
    border-radius: 5px;
    color: #fff!important;
    font-size: 13px !important;
    font-weight: 500 !important;
}
/* .checkout-btn:disabled{
 opacity: .7 !important;

} */

.badge-btn{
  position: relative;
  top: -15px !important;
  left:105px !important
}


#print, #print:hover , 
.cart-btn , .cart-btn:hover ,
.cart-btn:focus
,#print:focus{
  background: #9D2904!important;
  color: white;
  border: 0;
  margin-bottom: 10px;
}

@media print {
  /* .table-full-width {
    width: 1000px !important;
  } */
  .table-bordered td,
  .table-bordered th {
    border: 2px solid #e9ecee;
  }

  .table-bordered tr {
    border-bottom: 2px solid #e9ecee;
  }

  .vendor-print-box * {
    visibility: visible;
    border: 0px solid transparent;
    margin-top: 30px;
    text-align: center;
    width: 380px !important;
  }

  #tablePrint {
    width: 500px !important;
  }

  .TableSec {
    height: max-content;
    width: max-content;
    overflow-y: none;
    overflow: visible !important;
    /* / margin-left: 190px; / */
    border: 1px solid black;
  }
  .vendor-name {
    font-size: 15px;
    text-align: center !important;
  }

  #print {
    display: none;
  }
}
#tablePrint tr td {
    font-size: 14px !important ;
}

.ant-modal-close-x{
  display: none !important;
}
.ant-modal-mask{
  background-color: rgba(0,0,0,0.3) !important;
}
.city-search , .city-search:hover 
, .city-search:active , .city-search:active
, .city-search:focus  {
  height: 30px !important;
  background-color: #9D2904!important;
  color: #fff !important;
  border-color: #9D2904 !important;
  padding-left: 25px !important;
  padding-right: 25PX !important;
  margin-left: 10px !important;
}
.city-search-delete , .city-search-delete:hover 
, .city-search-delete:active , .city-search-delete:active
, .city-search-delete:focus{
  height: 30px !important;
  background-color: #13CD52 !important ;
  color: #fff !important;
  border-color: #13CD52 !important;
  padding-left: 25px !important;
  padding-right: 25PX !important;
  margin-left: 10px !important;
}


.city-search-edit , .city-search-edit:hover 
, .city-search-edit:active , .city-search-edit:active
, .city-search-edit:focus{
  height: 30px !important;
  background-color: #d8bb14 !important;
  color: #fff !important;
  border-color: #d8bb14 !important;
  padding-left: 20px !important;
  padding-right: 20PX !important;
  margin-left: 20px !important;

}

.selectedCity{
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #000 !important;

}

.checkIcon{
  font-size: 14px;
  padding-right: 10px;
  font-weight: 900;
}
.closeIcon{
  font-size: 14px;
  color: red;
  font-weight: 900;
}
.checkout-btn2{
  width: 152px !important;
}

.back-btn2:hover {
  border: 1px solid #b59b56 ;
  border-radius: 4px;
  color: #b59b56;
  height: auto;
}

.back-btn2 {
 border: 1px solid #b59b56 ;
  border-radius: 4px;
  color: #b59b56;
  height: auto;
}

@media screen and (min-device-width: 550px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1) {
  .cartSec {
    height: 350px;
}
  .productVendorCard img {
    width: 50px;
}
#print i{
  font-size: 15px !important;
}
  .ant-card-meta-title,p ,#tablePrint tr td,.username, .header-info-wrapper, .menu-options, .btn-link, .btn-link-ok, p, b, a, td, .ant-btn-link, button, .ant-input, .ant-tag,#unitType, #tablePrint .ant-table-thead>tr>th, .ant-table-tbody>tr>td {
    font-size: 10px !important;
}
  .vendorProduct .productVendorCard {
    height: 80px;
}
#print, #print:hover, .cart-btn, .cart-btn:hover, .cart-btn:focus, #print:focus {
  font-size: 10px !important;
  height: 25px;
}
.ant-descriptions-item-label,.productVendorCard .ant-card-meta-title,.ant-descriptions-item-content{
  font-size: 12px !important;
}
.totalBillSummary .ant-descriptions-item {
  padding-bottom: 6px;
}
}