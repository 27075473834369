.table-flex {
  display: flex;
  justify-content: space-around;
  margin: auto;
}

.ant-input-search-button {
  background: green;
  color: white;
  border-radius: 3px;
  line-height: 2px;
  height: 40px;
  border: none;
}


.dining-logo {
  /* height: 75px; */
  width: 80px;
}

/* .table-wrapper {
  width: 130px;
  height: 130px;
  background: #ffa733;
  color: white;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
} */
.table-wrapper {
  width: 130px;
  height: 130px;
  background-image: linear-gradient(45deg, #ffd700, #dcbb68) !important;
  /* color: "white"; */
  border-radius: 0;
   display: flex;
  flex-direction: column;
}

.table-wrapper:hover {
  background: #ffa733;
  opacity: 0.8;
}

.table-wrapper:focus {
  background-color: #ffa733;
}

.item-1 {
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  width: 150px;
}

#add-button {
  background: #424244;
  color: white;
  width: 50px;
  align-self: center;
  /* margin: 10px 0; */
}

.no-dining {
  position: relative;
  filter: brightness(0) invert(1);
  opacity: 0.5;
}

.text-block {
  position: absolute;
  width: 35%;
  height: 2px;
  transform: rotate(45deg);
  background: white;
  margin-left: 20px;
  margin-top: -30px;
}

.text-block1 {
  position: absolute;
  width: 35%;
  height: 2px;
  transform: rotate(135deg);
  background: white;
  margin-left: 20px;
  margin-top: -30px;
}

.shadow-dash {
  border: none;
  padding: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

@media (max-width: 414px) {
  .content1 {
    margin-top: -11%;
    margin-left: 4%;
    width: 100%;
  }
}

@media (max-width: 900px) {
  .table-flex {
    flex-direction: column;
    width: 150px;
    align-items: center;
  }
}

@media (max-width: 414px) {
  .ant-input-search {
    width: 110%;
    margin-left: 28px;
  }
}
