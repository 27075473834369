.billing {
  max-width: 100%;
}

/* .billing .ant-col{
  box-shadow: none !important;
  background-color:none !important;
} */
/* .billing .dashboard-flex {
  margin-bottom: 100px;
} */

.billing .ant-affix {
  top: 70px !important;
}

#something4 {
  display: none;
}

.report-container-flex {
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-right: 6vw;
}

#flex-crm {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 30px;
}

.cus-blur {
  background: rgba(0, 0, 0, 0.6);
  background: blur(1px);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.close {
  position: absolute;
  top: 3px;
  right: -182px;
}

#cus {
  position: absolute;
  top: 10%;
  left: 17%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 50%;
  min-height: 70vh;
}


#myBill {
  height: 45vh;
  margin-bottom: 250px;
  display: block;
  overflow-y: scroll;
}

#myBill .fa-trash {
  font-size: 16px;
}

.fa-trash {
  cursor: pointer;
  padding: 5px;
}

li {
  position: relative;
  list-style: none;
}

.input2 {
  padding-left: 10px;
  width: 300px;
  padding: 5px 10px;
  margin: 6px 0;
  border: none;
  border-bottom: 1px solid #8e8a85;
  font-size: 14px;
  outline: none;
  color: black;
  border-radius: 4px;
  background-color: white;
}

.input2:focus {
  border-bottom: 2px solid black;
}

#submit-landing2 {
  cursor: pointer;
  /* background-color: #424244; */
  /* text-transform: uppercase; */
  padding: 10px 15px;
  border-radius: 4px;
  border: 1px solid white;
  /* min-width: 200px; */
  color: white;
  /* min-width: 150px !important; */
  margin-right:22px;
}

textarea {
  resize: none;
}

.horizontal.floating-buttons .button-style1 {
  display: inline-block;
}

.billing .horizontal.floating-buttons {
  max-width: 520px;
  position: absolute;
  z-index: 1;
  padding: 0 5px;
  border-radius: 5px;
}

.billing .horizontal.floating-buttons #container {
  position: fixed;
}

.billing .floating-buttons {
  margin-left: 0px;
}

/* BIlling section*/

.bill {
  width: 100%;
  height: auto;
}

.bill-container {
  margin-left: 10px;
}

.black-line {
  position: absolute;
  background: #222;
  height: 8px;
  border-radius: 20px;
  left: -5px;
  top: -4px;
  width: calc(100% + 10px);
  z-index: 0;
}

/* .bill-button-container {
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
} */

.ant-btn.print,
.ant-btn.send {
  padding: 10px 50px;
  text-align: center;
  width: auto;
  height: auto;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  /* margin: 20px 0 0 0; */
}

.ant-btn.print {
  background: #f2bb15;
  color: #222;
}

/* .ant-btn.send {
  background: #19467f;
} */

.white-line {
  position: absolute;
  background: #fff;
  height: 8px;
  border-radius: 20px;
  width: 100%;
  z-index: 0;
}

.outer {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #fff;
}

.form-container {
  background: #fff;
  padding: 10px;
  height: 100%;
  width: 100%;
}

.form {
  min-height: 60vh;
  background-color: #f2f2f2;
  color: #383838;
  position: relative;
}

table {
  width: 100%;
}

.table2 {
  color: #d4d6d7;
  margin-top: 100px;
  position: absolute;
  max-width: 100%;
  bottom: 0;
}

.table2 td {
  color: #333;
}


.product-table td {
  border-bottom: 1px solid #d4d6d7;
  border-collapse: collapse;
  padding: 5px 15px;
  color: #383838;
}

.table2 td.sub-total {
  border-bottom: none !important;
  font-size: 18px;
}

.table2 td.tax {
  border-top: none !important;
}

.table1 .text-right.single-price,
.table2 .text-right.single-price {
  font-weight: normal;
  font-size: 15px;
  margin-right: 15px;
}

.table1 .single-price,
.table2 .price {
  font-weight: bold;
  font-size: 20px;
}

.table1 .single-quantity {
  margin-right: 12px;
}

.table1 .tax,
.table2 .tax {
  font-size: 15px;
}

.table2 .sub-total.name {
  color: #888;
}

.table2 .time {
  font-size: 10px;
}

.table2 .date {
  font-size: 10px;
  text-align: right;
}

.table2 table {
  padding: 20px;
}

.product-table td,
.summary-table td {
  min-width: 0 !important;
}

.product-table .product-name {
  font-weight: 700;
  text-transform: capitalize;
}

.table2 td {
  border-bottom: 2px dashed #d4d6d7;
}

.table2 .light {
  font-weight: bold;
  color: #aaa;
}

tbody tr:last-child td,
.table2 tr:last-child td {
  border-bottom: none;
  outline: none;
}

.billing #container {
  display: flex;
}

.billing #container .button-style1 {
  flex: 1 0 25%;
}

/* .bottom-bar {
  position: fixed;
  bottom: 0px;
  background: #fff9e9;
  margin-left: -20px;
} */

.scrollbar {
  margin-left: 15px;
}

table .fas-trash {
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
}

table .trash-td {
  max-width: 40px;
  padding: 15px 0;
}

.switch-bar {
  display: flex;
  justify-content: center;
}

.switch-bar .ant-btn {
  background: #222;
  color: #fff;
}

.billing .scrollbar {
  overflow-x: scroll;
}

.table-number {
  display: block;
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
  background: #fefefe;
  -webkit-box-shadow: -2px 2px 2px #ddd;
  box-shadow: -2px 2px 2px #ddd;
  border-radius: 3px;
  margin: 5px 0 10px 0;
}

.order-search {
  /* width: calc(100% - 100px); */
  /* width: calc(70%); */
  margin: 20px 0;
}

.billing .back-button {
  /* margin: 12px 0; */
  border: 1px solid #b59b56;
  border-radius: 4px;
  padding:3px 20px;
}

.dashboard-flex .order-search input.ant-input {
  padding: 17px;
  margin: 0 0 5px 0;
  background: #fff !important;
  color: #333 !important;
}

.table-number .right-text {
  font-size: 16px;
}

.table-number .small {
  font-size: 70%;
}

/* @media (max-width: 1280px) {
  .bill-container {
    margin-left: 70px;
  }
} */

@media (max-width: 1110px) {
  .billing .scrollbar {
    margin-bottom: 15px;
  }
  .switch-bar {
    margin-top: 60px;
  }
  .bill-container {
    width: 90%;
    display: flex;
    justify-content: space-around;
    margin-left: 0;
    margin: 0 5%;
  }
  .bill {
    max-width: 400px;
  }
  .mobile-dashboard-flex {
    margin-left: 80px;
  }
  .table-number {
    margin: 10px 20px 20px 20px;
  }
}

@media (max-width: 1024px) {
  #cus {
    margin-top: -30%;
  }
}

@media (max-width: 768px) {
  .mobile-dashboard-flex {
    margin-left: 0;
  }
}

@media (max-width: 414px) {
  #cus {
    margin-top: -15%;
  }
}
