.z-total {
    background-color: #d8bb14;
    height: 100px;
}

.z-box-shadow .ant-statistic-content {
    font-size: 25px !important;
}

.z-box-shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    height: 100px !important;
}

.z-box-shadow svg,
.ant-statistic-content-value-int {
    height: 18px;
}
.printt-btn , .printt-btn:hover , .printt-btn:focus {
    background-color: #d8bb14 !important;
    border: 1px solid #dcbb68 !important;
    color: #fff !important;
    right: 0 !important;
}
@media print {
    #tablePrint * {
        visibility: visible;
        top: 0px;
        border: 0px solid transparent;
        
    }
    .TablePrint{
        height: max-content;
        width:max-content;
        overflow-y: none;
        margin-left: 40px;
        border: 1px solid black; 
    }
}