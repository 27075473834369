/* .report-container {
    margin: 20px 0;
    padding: 10px 0;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
    display: flex;
    justify-content: space-between;
    background: #f9f9f9;
    border-radius: 3px;
} */

/* time picker style changes */

.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 324px;
}

.ant-time-picker-panel-combobox {
  zoom: 1;
  width: 324px;
}

.ant-radio-button-wrapper {
  padding: 0 32px;
  margin: 11px;
}

.ant-time-picker-panel-select:first-child {
  margin-left: 0;
  border-left: 0;
  width: 50%;
  font-size: 24px;
}

.ant-time-picker-panel-select {
  position: relative;
  float: left;
  width: 56px;
  max-height: 193px;
  overflow: hidden;
  font-size: 24px;
  border-left: 1px solid #e8e8e8;
}

.ant-time-picker-panel-select ul {
  width: 100%;
  margin: 0;
  padding: 0 0 160px;
  list-style: none;
}

.ant-time-picker-panel-select {
  width: 50%;
}

/*  end of timePicker changes */

.ant-calendar-ok-btn {
  color: #fff !important;
}

.back-button-border {
  font-size: 13.5px;
}


.ant-table-pagination.ant-pagination {
  float: right;
}

.ant-table-bordered ant-input {
  border: 1px solid #f3f3f3;
}

.ant-table-thead > tr > th {
  background: #f3f3f3;
}

/* .ant-table-tbody > tr > td {
  background: #fff;
} */

.ant-table-tbody td span a {
  width: 50%;
  display: inline-block;
  margin: 0 !important;
  padding: 0 5px;
}

.ant-pagination-item,
.ant-pagination-prev,
ant-pagination-next {
  float: left;
}

#less-visible {
  font-weight: lighter;
}

.different-reports {
  width: 90%;
  margin-top: 5vh;
  display: flex;
  justify-content: space-around;
}

.ant-calendar-picker-input input,
.ant-calendar-picker-input span {
  float: left;
}

#flex {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 400px;
  margin-bottom: 30px;
}

.box {
  outline: none;
  border: none;
  padding: 20px 32px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px;
  height: 70px;
  width: 250px;
}

.orange-bg {
  margin-bottom: 15px;
  background: linear-gradient(#ffbe71, #ffa733);
  color: black;
}

.black-bg {
  margin-bottom: 15px;
  color: white;
  background: linear-gradient(#444, #111);
}
.gold-bg {
  margin-bottom: 15px;
  background: #d7be69;
  color: black;
}
.silver-bg {
  color: black;
  font-weight: bold;
}

.product_ul {
  display: block;
  list-style-type: disc;
  margin-top: 0.5em;
  margin-bottom: 1 em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
}
.product_ul ul {
  display: block;
  list-style-type: disc;
  margin-top: 0.5em;
  margin-bottom: 1 em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 25px;
}

.product_ul ul li {
  display: list-item;
  float: none;
  list-style: none;
}
.product_ul ul li .fa-remove {
  color: rgb(250, 42, 27);
}
.product_ul ul li .fa-check {
  color: rgb(27, 250, 64);
}
.product_ul li {
  display: list-item;
  float: none;
  list-style: none;
}
.mycontent-left {
  border-right: 1px solid #333;
}
.mycontent-right {
  padding-left: 20px;
}
.od .ant-card-grid {
  width: 100%;
}
.inner-main .card-body .card{
  border: none !important;
}
@media (max-width: 1200px) {
  .different-reports {
    flex-direction: column;
    align-items: center;
  }
  /* .report-container {
    max-width: 90%;
    margin: 20px auto;
  } */
}

@media (max-width: 380px) {
  .report-container {
    margin-left: 10%;
  }
  .different-reports {
    margin-left: 5%;
  }
}
.btn:hover {
  background-color: rgb(235, 215, 107);
}

@media (max-width: 414px) {
  .report-container {
    margin-left: -2%;
    margin-top: -9%;
    width: 100%;
  }
}

.badgeCount{
  right:24% !important;
  position: absolute;
  top:-5px;
  width:25px;
  height:25px;
  color:white !important;
}
.badge_Count {
  right: 10% !important;
  position: absolute;
  top: -10px;
  padding: 9px 12px;
  color: white !important;
  border-radius: 50px;
}
.requestedTimeClass{
  font-size: 18px !important;
  font-weight: 600 !important;
  color: black;
}
.requestedTimeClass1{
  font-size: 15px !important;
  font-weight: 500 !important;
  color: black;
}
.highlighted-row{
  font-size: 11px !important;
  font-weight: 700 !important;
  color: blue !important;
}
.view-btn,.view-btn:hover{
  border: 1px solid #dcbb68 !important;
  color: #fff !important;
}
.confirmButton , .confirmButton:hover , .confirmButton:active{
  background-color: orange !important;
  text-align: "center" !important;
  border-radius: 5 !important;
  color: white !important;
  border: 1px solid orange !important;
}
.badge-status{
    border-radius: 5px  !important;
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1366px){
  .badge_Count{
    right:25% !important;
    padding: 7px 10px !important;
    top: -12px;
  }
}
