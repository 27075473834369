@media print {
  body * {
    visibility: hidden;
    margin-top: -10px;
  }
  #singleinvoice * {
    visibility: visible;
    top: -20px;
    margin-top: 0px;
    border: 0px solid transparent;
  }
  #singleinvoice {
    left: 0;
    /* margin-top: -100px; */
    /* top: 0; */
  }
  #printbtn * {
    visibility: hidden;
  }
  #markpaidbtn {
    visibility: hidden;
  }
  #markrevertbtn {
    visibility: hidden;
  }
  #producttab {
    margin-top: 40px;
  }
}
.text-color {
  color: rgba(0, 0, 0, 0.85);
}
.back-btn:hover {
  color: white !important;
  border: 1px solid white;
  background-color: transparent;
}
.back-btn {
  border-radius: 4px;
  height: auto;
}
.row-font {
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
}
.ant-btn-primary {
  background-color: #1890ff !important;
}
