body {
    font-family: Myriad pro regular;
}

#right-menu-main-wrapper {
    position: absolute;
    /* margin-top: 60px; */
    /* padding-bottom: 20%; */
    /* margin-left: 10px; */
    width: 100%;
    /* padding-left: 20px; */
    display: inline-block;
}

.circle {
    border-radius: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    justify-content: column;
}

.admin-circle1:hover {
    background-color: #40a9ff;
    color: white;
    opacity: 0.8;
}

.admin-circle1:focus {
    background: #40a9ff;
    color: white;
}

.hide {
    display: none;
}

.admin-setting-buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
}

.admin-option-setting {
    display: flex;
    flex-direction: column;
    min-height: 350px;
    align-items: center;
}

.admin-option-items {
    height: 40px;
    width: 80%;
    background: #ffa733;
    border-radius: 4px;
    margin: .5em 0;
}

.admin-options-text {
    color: black;
    display: flex;
    justify-content: space-around;
    font-weight: bold;
}

.admin-circle1 {
    width: 80px;
    height: 80px;
    background: #40a9ff;
    border-color: #40a9ff;
    color: white;
    margin-bottom: 10px;
}

@media (max-width: 1200px) {
    .circle-button-small {
        height: 50px;
        width: 50px;
    }
}

/* .circle-button1 {
    width: 80px;
    height: 80px;
    background: green;
    border-color: green;
    color: white;
    margin-bottom: 10px;
}

.circle-button1:focus {
    background-color: green;
    color: white;
}

.circle-button1:hover {
    background: green;
    opacity: 0.8;
    color: white;
}

.circle-button2 {
    width: 80px;
    height: 80px;
    background: #3699fa;
    border-color: #3699fa;
    color: white;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.circle-button2:focus {
    background-color: #3699fa;
    color: white;
}

.circle-button2:hover {
    background: #3699fa;
    opacity: 0.8;
    color: white;
}

.return-image-right {
    width: 25px;
    height: 25px;
    filter: brightness(0) invert(1);
}

.return-image-right-black {
    width: 25px;
    height: 25px;
    filter: brightness(0);
}*/

.right-upper-button {
    display: flex;
    justify-content: space-between;
} 

.shadow {
    border: none;
    padding: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

/* .circle-button3 {
    width: 80px;
    height: 80px;
    background: #ffa733;
    border-color: #ffa733;
    color: white;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
} */

/* .circle-button3:hover {
    background: #ffa733;
    color: white;
} */

/* .circle-button3:focus {
    background-color: #ffa733;
    color: white;
} */

/* .circle-button4 {
    width: 80px;
    height: 80px;
    background: linear-gradient(#f2f2f2, grey);
    border-color: grey;
    color: black;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.circle-button4:focus {
    background: linear-gradient(#f2f2f2, grey);
    color: black;
} */

.leftButton {
    min-width: 73px;
    width: 100px;
    height: 40px;
    background: linear-gradient(#ffbe71, #ffa733);
    color: white;
    margin: 8px;
}

.rightButton {
    min-width: 73px;
    width: 100px;
    height: 40px;
    background: linear-gradient(#f2f2f2, grey);
    border-color: grey;
    color: black;
    margin: 10px;
}

/* .card-style {
    background: #424244;
    border-top-color: black;
} */

Button:hover {
    opacity: 0.8;
}
.ant-card-body{
    padding: 15px 0;
}
@media (max-width: 768px) {
    #right-menu-main-wrapper {
        z-index: -20;
    }

    #right-menu-main-wrapper {
        position: fixed;
        width: 100%;
        height: 100%;
        background: #333;
        top: -1px;
        left: 0%;
        transition: 0.5s;
    }
}

@media (max-width: 673px) {
    #right-menu-main-wrapper {
        z-index: -20;
    }

    #right-menu-main-wrapper {
        position: fixed;
        width: 100%;
        height: 100%;
        background: #333;
        top: -1px;
        left: 0%;
        transition: 0.5s;
    }
}
