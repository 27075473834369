.small-text {
  font-size: 14px;
}

#flex-stock {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 30px;
}

.stock-bg {
  color: black;
  background: linear-gradient(#ffa740, #ffa733);
}
