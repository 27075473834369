#left-side-bar {
  display: block;
  position: fixed;
  left: -236px;
  width: 300px;
  z-index: 2;
  transition: 0.5s;
  z-index: 60;
}


.menu-item {
  display: grid !important;
}

.hide-f {
  display: none;
}

.menu-background {
  background-color: #424244 !important;
  height: 100%;
  display: flex;
}

.ant-menu-item-selected {
  background: #ffa733 !important;
  color: #222222 !important;
}

.menu-button {
  height: 80px !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding: 0 9px !important;
  width: 80px;
  text-align: center;
  border: 1px solid #2a2c2f;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 12px;
  font-family: arial, helvetica, sans-serif;
  padding: 10px 50px 10px 50px;
  text-decoration: none;
  display: inline-block;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.3);
  font-weight: bold;
  color: #ffffff;
  background-color: #555;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#555),
    to(#111)
  );
  background-image: -webkit-linear-gradient(top, #555, #111);
  background-image: -moz-linear-gradient(top, #555, #111);
  background-image: -ms-linear-gradient(top, #555, #111);
  background-image: -o-linear-gradient(top, #555, #111);
  background-image: linear-gradient(to bottom, #555, #111);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#555, endColorstr=#111);
}

.ant-input-dark input {
  background-color: #2a2a2a !important;
  border-color: #2a2a2a !important;
  color: white;
  height: 40px;
  min-height: 0px !important;
  margin: 10px 0;
}

.username {
  color: #000;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 400;
  padding: 0px 5px;
}
.username i{
  padding-left: 5px;
}

.ant-layout-sider-collapsed {
  overflow: auto;
  height: 100vh;
  width: 80px;
  position: fixed;
  left: 0px;
  flex: 0 0 80px;
  max-width: 66px;
  min-width: 60px;
}

.menu-text {
  line-height: 18px;
  font-size: 9px;
}

.menu-text:hover {
  background: #ffa733;
}

.content-style {
  background: white;
  height: 100vh;
  width: 100%;
}

.main-menu-style {
  display: flex;
  flex-direction: column;
}

#show-bill {
  display: block;
  float: left;
}

#hide-bill {
  display: none;
}

#right-menu {
  display: block;
  right: 0;
  position: fixed;
  margin-top: 60px;
}

.text-right {
  text-align: right;
}

.floating-position {
  margin-top: 100px;
  margin-left: 80px;
}


.content-left {
  left: 50px;
}

.avatar-header {
  height: 40px;
  width: 40px;
  margin: 10px 0 10px 3px;
}

.header-background {
  background-color: #424244 !important;
  position: fixed;
  width: 100%;
  z-index: 2;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  height: 60px;
  padding: none;
}

.ant-layout-header {
  padding: 0 10px 0 50px;
  display: flex;
  width: 100%;
}

.header-div-right {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  height: 100%;
}

.header-div-left {
  margin-left: -40px;
  width: 40%;
  display: flex;
  justify-content: flex-start;
  height: 100%;
}

.header-input {
  border-radius: 29px;
  width: 60%;
  opacity: 0;
}

@media (max-width: 1200px) {
  .header-input {
    width: 58%;
  }
  .header-div-right {
    width: 80%;
  }
  .hide-logo2 {
    display: none;
  }
}

@media (max-width: 1100px) {
  .header-div-left {
    width: 22%;
  }
  .inner-flex-company {
    max-width: 100px;
    max-height: 70px;
  }
  .company-logo-header2 {
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
    max-width: 100% !important;
    max-height: 100% !important;
    width: auto !important;
  }
  .header-div-right .header-input {
    max-width: 200px;
  }
  .hide {
    display: none;
  }
  .back-button {
    right: 0px;
  }
  .res-profile-wrapper {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .hide-logo {
    display: none;
  }
  .hide1 {
    display: none;
  }
  .hide-logo2 {
    display: block;
    margin-top: 5px;
    margin-left: 2px;
  }
  .inner-flex {
    margin-left: -50px;
  }
  .username span {
    display: none;
  }
}

.header-search-icon {
  color: white;
}

.header-user-info-icon {
  padding-left: 10px;
  color: white;
}

.avatar-general {
  padding-left: 5px;
  color: #ffa733;
}

.item-header-flex1 {
  align-self: center;
  cursor: pointer;
}

.item-header-flex2 {
  align-self: center;
  display: none;
}

.border-right {
  padding: 10px;
  border-right: 1px solid black;
}

.text-header {
  font-size: 12px;
}

.bar1,
.bar2,
.bar3 {
  display: block;
  width: 30px;
  height: 5px;
  background-color: #fff;
  border-radius: 2px;
  margin: 6px 0;
  z-index: 1;
  transition: transform 0.1s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.1s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.1s ease;
}

.item-header-flex1 {
  display: none;
}

/*
 * Just a quick hamburger
 */

.item-header-flex1 .bar1,
.item-header-flex1 .bar2,
.item-header-flex1 .bar3 {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #fff;
  border-radius: 3px;
  z-index: 1;
  transition: left-side-bar 500ms ease;
}

.item-header-flex2 .bar1,
.item-header-flex2 .bar2,
.item-header-flex2 .bar3 {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #fff;
  border-radius: 3px;
  z-index: 1;
}

.main-menu-style {
  display: block;
  position: fixed;
  left: 75px;
  top: 60px;
  z-index: 2;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */
  width: 75px;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

.main-menu-style .menu-item {
  padding: 10px 0;
  font-size: 22px;
}

/*
 * And let's slide it in from the left
 */

.company-logo-header {
  align-self: center;
  padding-left: 20px;
  max-height: 50px;
  cursor: pointer;
}

.company-logo-header2 {
  align-self: center;
  padding-left: 20px;
  height: 80%;
  width: 73px;
  cursor: pointer;
}

.hide-logo2 {
  display: none;
}

.inner-flex-company {
  width: 250px;
  padding-left: 20px;
}

#text-inner {
  color: #facc2f;
  margin-left: -32px;
}

#smaller-text {
  margin-top: -130px;
  margin-left: -25px;
  color: #facc2f;
  font-size: 8px;
}

.floating-buttons #container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  margin: 10px 0;
}

.floating-buttons {
  margin: 60px 0 0 100px;
  display: flex;
}

@media (max-width: 652px) {
  .floating-buttons {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }
  .inner-flex-company {
    width: 250px;
    padding-left: 15px;
  }
}

@media (max-width: 768px) {
  .item-header-flex1 {
    display: block;
  }
  #something {
    display: none;
  }
  #something2 {
    display: none;
  }
  .item-header-flex2 {
    display: block;
    margin: 15px 10px 0;
  }
  .avatar-header {
    height: 40px;
    width: 40px;
    margin-top: 8px;
  }
  .header-div-right {
    width: 80%;
  }
  .item-header-flex .header-input {
    width: 40%;
  }
  .hide-logo2 {
    display: block;
  }
  .floating-buttons #container {
    flex-direction: row;
    height: auto !important;
  }
  .floating-buttons {
    margin-top: 60px;
  }
}

@media (max-width: 768px) {
  .hide-logo2 {
    display: block;
  }
}

@media (max-width: 682px) {
  #right-menu-main-wrapper {
    position: fixed;
    margin-top: 60px;
    padding-bottom: 20%;
    right: 0;
    width: 25%;
    padding-left: 20px;
  }
  #right-menu {
    right: 0;
    position: fixed;
    margin-top: 60px;
  }
}

@media (max-width: 1024px) {
  #right-menu {
    display: inline-block;
    right: 0;
    position: fixed;
    margin-top: 60px;
  }
}
