#something4 {
  display: none;
}

.report-container-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-right: 6vw;
}

.ant-table.ant-table-bordered .ant-table-title {
  background: white;
}

#flex-crm {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 30px;
}

.add-customer input {
  margin-top: 0.2em !important;
  margin-bottom: 0.2em !important;
}

.cus-blur {
  background: rgba(0, 0, 0, 0.6);
  background: blur(1px);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.close {
  position: absolute;
  top: 3px;
  right: -182px;
}

#cus {
  position: absolute;
  top: 10%;
  left: 17%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 50%;
  min-height: 70vh;
}

.form2 {
  height: 500px;
  width: 400px;
  border-radius: 4px;
  background-color: #ffa733;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 8px 6px -6px black;
  padding-bottom: 20px;
}

li {
  position: relative;
  list-style: none;
}

.input2 {
  padding-left: 10px;
  width: 300px;
  padding: 5px 10px;
  margin: 6px 0;
  border: none;
  border-bottom: 1px solid #8e8a85;
  font-size: 14px;
  outline: none;
  color: black;
  border-radius: 4px;
  background-color: white;
}

.input2:focus {
  border-bottom: 2px solid black;
}

#submit-landing2 {
  background-color: #424244;
  text-transform: uppercase;
  padding: 5px 15px 5px 15px;
  border-radius: 4px;
  border: 1px solid white;
  min-width: 200px;
  color: white;
}

textarea {
  resize: none;
}

@media (max-width: 414px) {
  #cus {
    margin-top: -15%;
  }
}

@media (max-width: 1024px) {
  #cus {
    margin-top: -30%;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
