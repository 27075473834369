
.ant-row:before,
.ant-row:after {
  content: none !important;
}

.dashboard-flex:after {
  content: "";
  flex: auto;
}

