.btn-fak {
  height: auto;
  border: 1px solid #dcbb68;
  color: #dcbb68;
  background-image: linear-gradient(45deg, #fff, #f6f3dd);
}
.fileList .ant-col {
  /* text-align: center; */
  /* min-height: 4rem; */
  /* width: 300px; */
  /* border: 1px solid hsl(43, 62%, 63%); */
  padding: 8px;
  /* background-image: linear-gradient(45deg, #fff, #f6f3dd); */
  /* border-radius: 5px; */
  /* margin: auto 5px; */
}
.fileList .ant-col a {
  display: flex;
  padding: 10px;
  border: 1px solid hsl(43, 62%, 63%);
  background-image: linear-gradient(45deg, #fff, #f6f3dd);
  border-radius: 5px;
}
.fileList .ant-col a img {
  height: 40px;
}
.fileList .ant-col a p {
  margin-bottom: 0.1em;
  font-size: 16px;
}
.report-table{
  padding: 13px;
}
.filter .ant-select{
  width: 100%;
}
.gr-sbH {
  color: rgb(201, 55, 55);
  font-size: 16px;
}
.gp-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.card .gp-box-total {
  border: transparent;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
.gp-box-total .card-body{
padding: 0px !important;
}
.print-btn .ant-spin .ant-spin-dot i{
  background-color: #000;
}
.btn1:hover{
  color:#1b3045;
  border:1px solid #1b3045;
}
.ant-statistic-content{
  font-size: 30px !important;
}
