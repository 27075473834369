/* .btn-fak {
  height: auto;
  border: 1px solid #dcbb68;
  color: #dcbb68;
  background-image: linear-gradient(45deg, #fff, #f6f3dd);
} */
/* .fileList .ant-col {
  text-align: center;
  min-height: 12rem;
  border: 1px solid hsl(43, 62%, 63%);
  padding: 15px;
  background-image: linear-gradient(45deg, #fff, #f6f3dd);
  border-radius: 5px;
  margin: auto 5px;
}
.fileList .ant-col a img {
  width: 100px;
}
.fileList .ant-col a p {
  margin-bottom: 0.1em;
  font-size: 16px;
} */
.report-table{
  padding: 13px;
}
.filter .ant-select{
  width: 100%;
}
.gr-sbH {
  color: rgb(201, 55, 55);
  font-size: 16px;
}
.gp-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.gp-box-total {
  background: #d8bb14;
  color: white;
  padding: 10px;
  border: transparent;
  /* box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; */
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.print-btn .ant-spin .ant-spin-dot i{
  background-color: #000;
}
.btn1:hover{
  color:#1b3045;
  border:1px solid #1b3045;
}
.onlineCard{
  padding:1.25rem 0.6rem !important;
}