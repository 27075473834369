.PLU-wrapper {
  margin: 15% 0;
  text-align: center;
  width: 100%;
}

.home-button-style {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 280px;
  height: 115px;
  box-shadow: -3px 0px 3px 0px #aaaaaa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
}

.silver-bg {
  color: black;
}

.home-button-color:focus,
.home-button-color:active,
.home-button-color:hover {
  background-color: #ffa733;
}

.home-button-color {
  background: linear-gradient(#ffbe71, #ffa733);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  color: #5d3d17;
  font-size: 20px;
  font-weight: bold;
}

.home-button-color-2 {
  background: linear-gradient(#d3d3d3, #777);
  font-size: 20px;
  font-weight: bold;
  color: black;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.home-button-color-2:focus,
.home-button-color-2:active,
.home-button-color-2:hover {
  background: linear-gradient(#f2f2f2, grey);
}

.home-button-driveout {
  width: 130px;
  height: 130px;
  background: green;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.home-button-driveout:hover {
  background: green;
  opacity: 0.8;
  color: white;
}

.home-button-driveout:focus {
  background-color: green;
  color: white;
}

.PLU-submit {
  margin: 6px auto;
  float: none;
  max-width: 155px;
}

.white-logo {
  width: 65px;
  height: 65px;
  filter: brightness(0) invert(1);
}

.plu-flex {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.home-button-style {
  margin: 5px 5px 5px 5px;
}

.eat-drink-logo {
  width: 40px;
  height: 40px;
  filter: saturate(3);
}

/* @media (max-width: 320px) {
  .test1 {
  }
  .plu-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

@media (max-width: 360px) {
  .test3 {
    margin: 0 auto;
  }
  .plu-flex {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
}

@media (max-width: 414px) {
  .test3 {
    margin: 0 auto;
  }
  .plu-flex {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
} */

@media (max-width: 768px) {
  .test3 {
    margin: 10px auto;
  }
  .plu-flex {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1024px) {
  .test3 {
    margin: 10px auto;
  }
  .plu-flex {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .home-button-driveout {
    margin-left: 11px;
  }
}
