.categoryListTable .ant-table table {
  table-layout: fixed;
}
.catForm .ant-form-item-control-wrapper{
  display: block !important;
}
.search{
  width:145px;
}
.back-btn:hover{
  color:white !important;
  border:1px solid white;
}