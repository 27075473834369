.tfl_banner{
    background-image: url("./../../../assets/tflbg.jpg");
    background-repeat: no-repeat;
    display:flex;
    justify-content: center;
    position:relative;
    height:100vh;
    align-items: center;
}

.tfl_logo{
    background-color: white;
    position:absolute;
   background-size: 100% 100%;
    background-image: url("./../../../assets/tfllogo.png");
    background-repeat: no-repeat;
    width: 400px;
    height: 400px;
}
.tfl_Points{
    position: absolute;
    height: 40px;
    width: 400px;
    border: 3px solid white;
    bottom: -53px;
    color: white;
    text-align: center;
}
