.button-style-card{
    margin: 2rem;
    padding: 5px;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
    background-color: white;
    -webkit-animation: scaledown .4s linear;
    animation: scaledown .4s linear;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}
.card-bord{
margin-top:15px;
}
.heading{
    text-align:center;
    color:#b59b56 !important;
}
.card-align{
    display:flex;
}
.drinks-card-border{
    border-left:0px solid transparent !important;
    width:auto !important;
}
