.add-employee.edit-profile {
  background: #f5f5f5 !important;
}

.add-employee.edit-profile input {
  color: #333;
  border-bottom: none !important;
}

.file-input {
  max-width: 200px;
}

.add-employee.edit-profile h2 {
  color: #333;
}