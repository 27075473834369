.floating-buttons::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    width: 6px;
    height: 5px;
}

.floating-buttons::-webkit-scrollbar {
    width: 6px;
    height: 5px;
    background-color: #F5F5F5;
}

.floating-buttons::-webkit-scrollbar-thumb {
    width: 6px;
    height: 5px;
    background-color: #000000;
    border: 2px solid #555555;
}

.main-button {
    background: #ffa733 !important;
    color: #222222 !important;
    border-color: #ffa733 !important;
}

.open-ac-button {
    background: #8f8b8a !important;
    color: #222222 !important;
    border-color: #ffa733 !important;
}

.table-view-button {
    background: #65e8b1 !important;
    color: #222222 !important;
    border-color: #ffa733 !important;
}

.company-logo {
    width: 100%;
    height: 100%;
    filter: brightness(0);
}

.tfl-button {
    background: #223867 !important;
    color: #222222 !important;
    border-color: #ffa733 !important;
}

.web-button {
    background: #d529b8 !important;
    color: #222222 !important;
    border-color: #ffa733 !important;
}


.button-size {
    width: 90px;
    height: 90px;
    border: none;
    padding: 10px;
    margin: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.button-text-style {
    display: grid;
    font-weight: bold;
    font-size: 11px;
    color: white;
}

/*for billing purpose*/

#container-billing {
    margin-top: 80vh;
    margin-left: 30vw;
    margin-top: 100px;
    margin-left: 100px;
    background-color: #424244;
}

.input-general {
    width: 80%;
    padding: 8px 20px;
    margin: 15px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.customer-submit {
    width: 30%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 5px 5px #BEBEBE;
    outline: none;
}

.customer-submit:hover {
    background-color: #45a049;
}

@media (max-width: 768px) {
    .floating-buttons {
        overflow-x: scroll;
        white-space: nowrap;
        width: 100%;
        margin: 60px 0 0 0;
        justify-content: center;
    }
    #container .button-style1 {
        display: inline-block;
    }
}