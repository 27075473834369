.main-summary {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
#displayrow .ant-card {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23) !important;
    border-radius: 10px !important;
}
.itemName1234{
    font-size: 14px !important;
    font-weight: 600 !important;
    color: black;
    padding-left: 10px ;
    padding-right: 10px;
}
#row1{
    height: 100vh;
    justify-content: space-around !important;
    padding: 15px 50px;
    position: relative !important;
  }

.card {
    text-align: center;
    justify-content: center;
    border-radius:10px,
}

.heading-h3{
    font-size: 1.5rem;
    font-weight: 500;
    color: black;

}
.divider{
    margin: 0px 0 !important;
}
.orderBtn{
    background-color: #9D2904!important;
    border: none;
    text-align: center;
    color: #fff!important;
    font-size: 13px !important;
    font-weight: 500 !important;
    border-radius: 5px;
    padding: 8px 30px;
}

.headingSeparation{
    background: #d9d9d9;
    padding: 10px 5px;
    border-radius: 5px;
}

.tickCheck{
    width: 40px;
    height: 40px;
    color:  #13CD52
    ;
    margin-right: 10px
}
.loading {
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: white;
    border-radius: 10px;
    letter-spacing: .5px;
}
.loading h3{
    padding-top: 20px;
}
.loading h4{
    padding-top: 10px;
    color: darkgray;
    font-size: 24px !important;
}
.loading h3, .loading h5 ,.loading h2{
    color: #000;
}
.payCancel-btn , .payCancel-btn:hover{
        background-color: #9D2904!important;
        border: none;
        text-align: center;
        color: #fff!important;
        font-size: 13px !important;
        font-weight: 500 !important;
        border-radius: 5px;
        padding: 8px 235px;
        margin-bottom: 40px;
}
.itemName
{
  text-transform: capitalize;
  font-size: 14px!important;
  letter-spacing: .4px !important;
  color: black !important;
}