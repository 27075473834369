.change-stock-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.select-stock {
  margin-top: 10px;
  height: 40px;
  width: 300px;
  font-size: 20px;
  border-radius: 5px;
  padding: 9px;
  outline: none;
}

.select-stock:hover {
  box-shadow: 0 8px 6px -6px black;
}

.change-stock-flex-header {
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.confirm-button {
  margin-top: 30px;
  height: 50px;
  min-width: 100px;
  background: green;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-change {
  height: 50px;
  width: 50px;
  font-size: 30px;
  border-radius: 50%;
  padding: 9px;
  outline: none;
  margin-right: 10px;
  margin-left: 10px;
  transition-duration: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.add-stock {
  border: 1px solid green;
}

.add-stock:hover {
  background: green;
  color: white;
}

.reduce-stock {
  border: 1px solid red;
}

.reduce-stock:hover {
  background: red;
  color: white;
}

.button-change:hover {
  box-shadow: 0 8px 6px -6px black;
}

.stck {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 50%;
  min-height: 70vh;
  margin-left: -60px;
}

.stockform {
  margin-top: 4%;
  height: 500px;
  width: 400px;
  border-radius: 4px;
  background-color: #ffa733;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 8px 6px -6px black;
  padding-bottom: 15px;
}

li {
  list-style: none;
  float: right;
}

label {
  color: #fff;
  font-size: 20px;
}

.ant-select-dropdown-menu-item {
  width: 100%;
}
