.main-form {
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: papayawhip;
    margin: 0 auto;
    
  }
 
  .submit {
    background-color: #d8bb14 !important;
    border: 1px solid #dcbb68 !important;
    color: white;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    transition: color .15s ease-in-out,
      background-color .15s ease-in-out,
      border-color .15s ease-in-out,
      box-shadow .15s ease-in-out
  }
  
  .heading-form {
    font-size: 15px;
    font-weight: 700;
    margin: 10px;
  }
  
  .submit:hover,
  .submit:focus {
    background-color: #d8bb14 !important;
    border: 1px solid #dcbb68 !important;
    color: white;
  }
  
  .address-field,
  .address-field:focus {
    border: 0px solid !important;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .ant-form-item-label>label {
    color: #686b78!important;
    font-weight: 500;
    margin: 0;
  }
  
  .paymentBtn {
    background-color: #d8bb14 !important;
    border: 1px solid #dcbb68 !important;
    color: white;
    display: inline-block;
    font-size: 13px !important;
    text-align: center;
    vertical-align: middle;
    transition: color .15s ease-in-out,
      background-color .15s ease-in-out,
      border-color .15s ease-in-out,
      box-shadow .15s ease-in-out
  }
  
  .paymentBtn:hover,
  .paymentBtn:focus {
    background-color: #d8bb14 !important;
    border: 1px solid #dcbb68 !important;
    color: white;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    transition: color .15s ease-in-out,
      background-color .15s ease-in-out,
      border-color .15s ease-in-out,
      box-shadow .15s ease-in-out
  }
  
  .paymentBtn:disabled {
    background-color: #eedc74 !important;
    border: 1px solid #eedc74 !important;
    color: white;
  
  }
  
  .paragraph p {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
font-family: 600 !important;
  
  }
  .ant-form input,.ant-form .ant-select-selection{
    border: 1px solid #d9d9d9;;
  }
  
  .productRow .product-bg{
    background-color: #d9d9d9;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  /* .totalDisplay{
    background-color: #9D2904;
    border-radius: 5px;
    color: #fff;
  } */
  .labelName{
    font-size: 14px !important;
    font-weight: bolder !important;
    color: black;
  }
  .itemName
  {
    text-transform: capitalize;
    font-size: 18px!important;
    font-weight: 700!important;
    letter-spacing: .4px !important;
    color: black !important;
  }
  .itemQuantity{
    font-size: 12px !important;
    font-weight: bolder !important;
    color: black;
  }
  .itemPrice {
font-size: 12px;
font-weight: bolder !important;
color: black;

  }
  
  .dropSelect{
    animation: glowingBtn 1.3s infinite;
    background-color: #9D2904;
    border: none;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    padding: 6px 10px 6px 15px;
  }
  .payBtn:disabled{
    opacity: .5;
  }
  .payBtn:disabled span{
    color: rgb(83, 82, 82);
  }
  .datePicker{
    font-size: 14px;
    font-weight: 600;
    color: black;
  } 
  /* .date-select input{
    padding-left: 50px;
    margin-left: 40px;
  } */
.datePicker{
  padding-top: 13px !important;
  padding-left: 20px ;
  font-size: 14px !important;
    font-weight: 600;
    margin: 0;
}
.datePicker > span{
  padding-top: 14px;
}
  .formVal{
    font-size: 12px !important;
    color: gray;
    font-weight: 400;
    margin: 0;
    padding-top: 10px;
    /* padding-left: 20px; */
  }
  .formVal > span{
    font-size: 14px !important;
    color: black;
    /* font-weight: bolder; */
  }
  
  .formSpan{
    font-size: 12px !important;
    color: gray;
    font-weight: 400;
    align-items: right !important;
    
  }
  .formSpan > span{
    font-size: 14px !important;
    color: black;
    /* font-weight: bolder; */
  }

  .para{
    font-size: 14px !important;
    padding-left: 10px;
    color: #686b78!important;
    font-weight: 500;
  }
  .timeslott2 {
    display: 'block';
    height: '30px';
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    align-content: flex-start;
    color: black !important;
    font-size: 8px !important;
    font-variant: tabular-nums;
    align-content: left;
    line-height: 3.5 !important;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    margin-right: 8px;
    white-space: nowrap;
    cursor: pointer;
    height: 1em;
    width: 40%;
    -ms-transform: scale(1.5);
    padding-left:20px ;
    -webkit-transform: scale(1.5);
    transform: scale(1) !important;
  }
  .reqDeliveryHeader{
    color: #686b78!important;
    font-weight: 500;
    font-size: 14px !important;
    margin: 0;
  }
  .heading-form{
    background-color: #9d2904;
    border-radius: 6px 6px 0 0;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 20px
  }
  .heading-form > span{
    background-color: rgb(255, 85, 0);
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    padding: 5px 20px;
    margin-left: 300px;
    border-radius: 2px;
  }

  .wrapper-form{
   box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 10px;
    height: 495px;
  }
    .wrapper-form2{
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      border-radius: 10px;
      height: auto !important;
      min-height: auto;
    }
 
    .datePicker >.ant-calendar-picker-icon{
    padding-top: 5px !important;
  }