.image-upload > input {
    visibility:hidden;
    width:0;
    height:0
  }

  .legg{
    font-size: 30px;
    margin-bottom:10px;
    color:#dcbb68; 
  }