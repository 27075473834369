.button-container {
  position: relative;
}

.close-button {
  position: absolute;
  /* right: 10px; */
  /* top: 5px; */
  font-weight: 700;
  font-size: 20px;
  background: none;
  color: #fff;
  border: 1px solid #fff;
  padding: 5px 10px;
  margin:10px;
  cursor: pointer;;
}

.ReactModal__Content {
  padding: 0 !important;
}

.ant-switch {
  min-width: 140px;
  margin: 10px 15px;
  text-align: center;
  height: 34px;
  background: #f6f6f6;
  border: 1px solid #e9e9e9;
}

.ant-switch-inner {
  color: #878787;
  font-size: 12px;
  margin-left: 25px;
  margin-right: 6px;
  line-height: 1;
}

.switches {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.ant-switch::after {
  background-color: #d5d5d5;
  height: 30px;
  width: 30px;
}

.ant-switch-checked {
  font-weight: 700;
  background-color: #ffa733;
}

.ant-switch-checked .ant-switch-inner {
  color: #f9f9f9;
}

#submit-landing2 {
  background: #ffa733;
}

.add-employee {
  width: 400px;
  border-radius: 4px;
  background-color: #1b3045;
  color: white;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 8px 6px -6px black;
  padding: 20px;
}

.add-employee input,
.add-employee button {
  padding: 5px;
  margin: 15px 0;
}
.add-employee button{
  width:150px;
}

.add-employee h2,
.add-employee p {
  color: white;
}
.add-employee h2{
  font-size:30px;
}

.add-employee button {
  color: white;
  border: none !important;
}

.add-employee .checkboxes {
  display: inline-flex;
  font-size: 14px;
  line-height: 14px;
}

.add-employee .checkboxes input {
  margin: 0 5px 0 25px;
  line-height: 12px;
}

.add-employee .checkboxes label {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}

.add-employee .designation-title {
  font-size: 20px;
  font-weight: 400;
  margin: 15px 0;
}
