@-webkit-keyframes loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader {
  position: absolute;
  margin: 100px auto;
  border: 3.6px solid #ff974d;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  width: 40px;
  height: 40px;
  left: 42%;
  top: 50%;
  animation: loader-spin 2s linear infinite reverse;
  -webkit-filter: url(#goo);
  filter: url(#goo);
  box-shadow: 0 0 0 1px #ff974d inset;
}

.loader:before {
  content: "";
  position: absolute;
  -webkit-animation: loader-spin 2s cubic-bezier(0.59, 0.25, 0.4, 0.69) infinite;
  animation: loader-spin 2s cubic-bezier(0.59, 0.25, 0.4, 0.69) infinite;
  background: #ff974d;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  border-radius: 50%;
  width: 150%;
  height: 150%;
  top: 50%;
  left: -12.5%;
}