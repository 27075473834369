.card-data,
.card-data:focus,
.card-data:hover
{
    box-shadow: 2px 2px 10px #bababa, 0px 0px 6px #ffffff;
    border-radius: 20px;
    /* margin-left: 7px; */
    
}
/* .card-data:hover{
    box-shadow: 2px 2px 10px #bababa, 2px 2px 8px #ffffff;
    border-radius: 20px;
    height: 150px;
    width: 170px;
    animation: scale 0.4s linear;
    animation-fill-mode: forwards;
} */
.meta-tag > title{
    font-size: 11px !important;

}
.card-data img{
    height: 50px;
}

.card-data .ant-card-body{
    width: 100%;
}

.detail > .ant-card-meta-detail{
    height: 130px;
} 
.restaurantName{
    color: black;
    font-size: 14px !important;
    padding-bottom: 3px;
    font-weight: 400;
}
