.documents span {
  margin: 10px 0;
  display: block;
  font-weight: 700;
}

.document-img {
  max-width: 90%;
  margin: 15px;
}

.insurance-span {
  margin: 40px 0 10px 0 !important;
}

.insurance-img {
  max-width: 100%;
}

.ant-dropdown-menu {
  position: absolute;
  right: 0;
  overflow-y: scroll !important;
  height: 320px !important;
}


.profile-full-name {
  text-transform: capitalize;
}

.ant-dropdown-menu-item {
  float: left !important;
}


.change-branch::before {
  color: rgba(0, 0, 0, 0.85);
  content: "Change Branch: ";
}
