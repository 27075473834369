.report-container-flex {
  margin-top: 15px;
}
@media (max-width: 414px) {
  .report-container-flex {
    margin-top: -15px;
  }
  .different-reports {
    margin-left: 4%;
  }
}
