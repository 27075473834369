.homeLayout .navbar-brand {
  width: 18rem;
}
.homeLayout .bg-light {
  /* height: 91px; */
  background-color: #fff !important;
  border-bottom: 2px solid #b59b56;
}
.homeLayout .collapse .nav-link {
  color: #1b3045;
  text-align: center;
  padding: 0 1.2rem;
}
.homeLayout .collapse .nav-link :hover {
  color: #b59b56 !important;
}
#navbarNavDropdown svg{
  width:20px;
}
.header-info-wrapper {
  border: 1px solid #b59b56;
  background-image: linear-gradient(45deg, #1b3045, #07182c);
  border-radius: 70px;
  padding: 6px 20px;
  font-weight: 400;
  color: #fff !important;
}
.avatar-general {
  color: #fff !important;
}
.profile-drop {
  width: 20%;
  padding-right: 41px;
}
.bell-img {
  width: 25px;
  height: 25px;
  margin: 0 2rem;
}
.time-profile {
  justify-content: end;
  display: flex !important;
}
.profile-drop {
  display: flex !important;
}
.profile-img {
  border-radius: 50%;
  height: 65px;
  border: 2px solid #b59b56;
  width: 65px;
}
.d-flax {
  display: flex;
}
.p-tb-10 {
  padding: 10px 0;
}
.m-b-1 {
  margin-bottom: 1rem;
}
.m-b-p5 {
  margin-bottom: 0.5rem;
}
.profile-role {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 4px;
  margin: 0 4px;
  width: 150px;
}

.ant-drawer-content-wrapper {
  border: 1px solid #b59b56 !important;
  /* width: 640px !important; */
}
.nav-active {
  color: #b59b56 !important;
}
body {
  background: #07182c;
  /* background: url("../../assets/images/bg.png"); */
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.left-menu {
  position: fixed;
  width: 150px;
  top: 20%;
  left: 0;
}
.d-none {
  display: none;
}
.left-menu .list-group .list-group-item {
  border: 1px solid #b59b56;
  /* text-align: center; */
}
/* .left-menu .list-group .list-group-item a {
  color: #000;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}
.left-menu .list-group .list-group-item a i {
  color: #dcbb68;
} */
.left-menu .list-group .list-group-item a {
  color: #07182c;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  /* flex-direction: row; */
  align-items: center;
}
.left-menu .list-group-item, .left-menu .list-group .list-group-item a i, .product-table td {
  color: #dcbb68;
  padding: 8.5px 4.5px !important;
}
.list-group-item {
  padding: 0.45rem 1.25rem !important;
}
.left-menu .list-group li:first-child {
  border-radius: 0px 25px 0px 0px;
}
.left-menu .list-group li:last-child {
  border-radius: 0px 0px 25px 0px;
}
.ant-table-body .ant-table-row .ant-table-row-cell-break-word a .fa {
  color: #dcbb68;
}
.ant-table-body .ant-table-row .ant-table-row-cell-break-word button .fa {
  color: #dcbb68;
}

.floation-btn a:hover,
.floation-btn a:focus {
  color: rgb(220 187 104);
  background-color: #fff;
  border: 1px solid #ffd700;
  color: #ffd700;
}

.floation-btn a {
  position: fixed;
  padding: 15px 10px;
  border: 1px solid #d8bb14;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  bottom: 10px;
  right: 10px;
  color: #d8bb14;
  height: unset !important;
  background-color: #fff;
}
.floation-btn a.hidden {
  visibility: hidden;
}
.floation-btn a:last-child {
  padding: 13px 15px;
}
.floation-btn a:nth-child(1) {
  background-color: #fff;
  padding: 15px 15px;
}
.floation-btn a:nth-child(1).show {
  transform: rotate(0deg) translate(-100px) rotate(0deg);
}
.floation-btn a:nth-child(2) {
  background-color: #fff;
  padding: 15px 15px;
}
.floation-btn a:nth-child(2).show {
  transform: rotate(45deg) translate(-100px) rotate(45deg);
}
.floation-btn a:nth-child(3) {
  background-color: #fff;
  padding: 15px 15px;
}
.floation-btn a:nth-child(3).show {
  transform: rotate(90deg) translate(-100px) rotate(90deg);
}
.floation-btn a img {
  width: 33px;
}
.btn-ab {
  background-color: #d8bb14 !important;
  border: 1px solid #dcbb68 !important;
  color: #fff !important;
}
.btn-ab:hover {
  background-color: #ffd700;
  border: 1px solid #dcbb68;
  color: #000;
}
.btn-ab:focus {
  box-shadow: 0 0 0 0.2rem rgb(255 221 30);
}
.btn-ab:not(:disabled):not(.disabled):active,
.show > .btn-ab.dropdown-toggle {
  color: #fff;
  background-color: #d8bb14;
  border-color: #dcbb68;
}
.btn-ab:not(:disabled):not(.disabled):active:focus,
.show > .btn-ab.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.05rem rgb(181, 155, 86);
  /* box-shadow: 0 0 0 0.2rem rgb(255 221 30); */
}
.ant-table-tbody tr td button {
  border: none;
  box-shadow: none;
  background:none !important ;
}
.ant-switch-checked {
  background-color: #dcbb68;
  border: 1px solid #dcbb68;
}
.ant-pagination-item-active {
  border: 2px solid #dcbb68;
  color: #dcbb68;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border: 2px solid #dcbb68 !important;
  color: #dcbb68;
}
.back-btn {
  background-color: #000;
  color: #b59b56;
  float: right;
  margin-right: 20px;
  padding: 0 15px;
  height: 32px;
  text-align: center;
  border: 1px solid #b59b56;
  font-size: 20px;
}
.back-btn > i,
.ant-btn > span {
  display: inline-block;
  -webkit-transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  pointer-events: none;
}
.right-boxs {
  position: fixed;
  width: 180px;
  top: 18%;
  right: 0;
}
.right-boxs ul li a {
  display: flex;
  color: #dcbb68;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}
.right-boxs ul li {
  width: 7rem;
  background-color: #fff;
  border: 1px solid #a8822f;
  border-radius: 20px;
  height: 6rem;
}
.right-boxs ul li a img {
  width: 40px;
}
.right-boxs ul li a p {
  color: #000;
}
.right-boxs .title {
  font-size: 14px;
  margin-bottom: 0;
}
.right-boxs .list-group{
  align-items:center;
}
.right-boxs ul.list-group li {
  margin: 15px 0;
}
.report-container {
  margin-bottom: 20px;
  padding: 10px 0;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
  background-image: linear-gradient(45deg, #1b3045, #07182c);
  justify-content: space-between;
  border-radius: 30px;
  border: 2px solid #b59b56;
}
.report-container .item {
  padding: 0 25px;
  height: 36px;
  line-height: 36px;
  font-weight: 700;
  font-size: 1.5em;
  color: #b59b56;
}
.report-container .item span {
  font-weight: lighter;
}
.btn-link, .btn-link-ok {
  border: 1px solid #dcbb68 !important;
  background-image: linear-gradient(45deg, #ffd700, #dcbb68) !important;
  color: #000;
  min-width: 14em;
  font-size: 18px;
  /* border-radius: 0 !important; */
  padding: 10px 0;
}
.btn-link-ok{
  height: 95px;
}
.btn-link:hover, .btn-link-ok {
  color: #000 !important;
  text-decoration: none;
  background-image: linear-gradient(45deg, #ffd700, #ffd700);
}
.m-t-2r {
  margin-top: 2rem;
}
.m-t-1r {
  margin-top: 5rem;
}
.w-1000 {
  min-height: 800px;
}
.card-body.bg {
  background-color:none;
}

.ant-btn {
  background-color: transparent;
  white-space: initial;
}
/* .ant-btn:hover {
  background-color: transparent;
} */
.para {
  font-size: 12px;
  padding-top: 4px;
  margin:0 ;
}
.title {
  font-size: 15px;
  color: #1b3045;
  font-weight: bold;
}
html,
body {
  height: auto;
}
.nav-item:hover {
  animation: scale 0.4s linear;
  animation-fill-mode: forwards;
}
.btn-size:hover {
  animation: scale 0.4s linear;
  animation-fill-mode: forwards;
}
.ant-notification {
  top: 20% !important;
  z-index: 2000 !important;
}
.badge-cus {
  position: absolute;
  right: 13px;
  top: 5px;
  color: #fff;
  padding: 5px 8px;
  border-radius: 50px;
  font-size: 12px;
}
.badge-folder-cus {
  position: absolute;
  right: 25px;
  color: white !important;
  border-radius: 60px;
  padding: 5px 8px;
  font-size: 12px;
}
.badge-table {
  position: absolute;
  right: 35px;
  padding: 5px 8px;
  border-radius: 60px;
  color: white !important;  
  font-size: 12px;
}
@keyframes scaledown {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 1500px) {
  .header-info-wrapper {
    height: 60px;
    /* width:auto; */
  }
  .company-logo-header2 {
    width: 80px !important;
  }
}
.drawer {
  margin-top: 90px;
}
.d-position {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
#navbarNavDropdown-2 {
  justify-content: flex-end;
}

@media screen and (max-width: 1300px) {
  /* .card-body.bg {
    width: 900px;
    margin-left: 120px;
  } */
  .btn-link {
    min-width: 12em;
  }
  .homeLayout .navbar-brand {
    width: 8.5rem;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) {
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 5px 10px;
    font-size: 10px !important;
}
  .card.m-t-1r.w-1000 {
    margin: auto !important;
    width: 75% !important;
  }
  .report-container {
    /* margin-top: 80px !important; */
    padding: 0px !important;
  }
  /* .card-body.bg {
    width: 867px;
    margin-left: 67px;
  } */
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 5px 5px !important;
    overflow-wrap: break-word;
  }
  .left-menu, .right-boxs {
    width: 125px;
  }
}
@media only screen and (min-device-width: 600px) and (max-device-width: 1200px) {
  .button-container {
    margin-top: 140px !important;
  }
}

@media screen and (min-device-width: 600px) and (max-device-width: 1300px) and (-webkit-min-device-pixel-ratio: 1) {
  .button-container {
    margin-top: 93px;
  }
}
