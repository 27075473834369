@import "../node_modules/antd/dist/antd.css";
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}
.ant-switch {
  min-width: 65px !important;
  height: 25px !important;
}
.ant-switch::after {
  height: 23px !important;
  width: 23px !important;
}
.ant-switch-loading-icon,
.ant-switch::after {
  top: 0;
}
.p-file .ant-card-body {
  padding: 12px;
}
.paymentBtn {
  font-size: 11px;
  background-color: #ffa733;
  color: #222;
  border-color: #ffa733;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.no-hover:hover{
    opacity: unset !important;
}

.card-body{
  width: 100% ;
}


/*---------------scroll -----------------*/
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #A8A8A8; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #989898; 
}

/*----------------- Info Page ---------------------*/


/*----------------- Profile Page ---------------------*/
.profile-card .card-body{
  background: #fff !important;
  border-radius: 5px;
}

/*----------------- Product Category Page ---------------------*/
#prod-cat .ant-card{
  background: none !important;
}
#prod-cat .ant-col{
  padding: 0 !important;
}
/* #prod-cat form .catForm{ 
  display: flex;
  width: 100%;
  justify-content: space-between;
} */

/*----------------- added dashboard -----------------*/
.inner-main{
  height: 100vh;
  display: flex;
    justify-content: center;
}
.inner-main .main-c{
  /* padding: 0; */
  overflow-y: scroll;
  position: fixed;
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  background-clip: border-box;
}

.ant-table-wrapper{
  width: 100%;
}

.ant-progress-inner, .ant-progress-bg{
  height: 14px !important;
}
.ant-progress-bg{
  background: #12C751;
}

.tag-ansatee span {
  margin-bottom: 6px;
}

.ant-dropdown-link.change-branch {
  margin-bottom: 20px;
}

/* MarqueeComponent.css */
.marquee {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
  color: red !important;
  font-size: 12px !important;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
