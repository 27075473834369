
.graph-display{
    display: flex;
    justify-content: center !important;
    align-items: center;
}
.graph-display .ant-card{
    /* padding-top: 40px !important; */
    width: 350px !important;
    height: 350px !important;
}
.graph-display-big .card{
    width: 800px !important;
    /* height: 400px; */
}

.headings{
    font-size:1.25rem !important;
    /* font-weight: 800 !important; */
    /* color: black !important; */
}

.heading2{
    /* font-size: 14px !important;
    color: black !important;
    padding-left: 70px !important; */
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 14px;
    line-height: 1.4;
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }
  
  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }
  
  .ant-picker-panels > *:last-child {
    display: none !important;
  }
  
  .ant-picker-panel-container, .ant-picker-footer {
    width: 280px !important;
  }
  
  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important; 
  }
  .newDone{
    text-align: center !important;
  }