.cancelReasons .col {
    border: 1px solid #6c757d;
    margin: 8px 30px;
    width: 80%;
    justify-content: space-around;

}

.ant-notification {
    top: 22% !important
}

.orderType{
    color: red !important;
    font-size: 14px !important;
    font-weight: bold !important;
}
.ant-checkbox-inner{
  width: 20px !important;

}
.refuntDoneBtn{
  width: 60px;
  height: 28px;
  align-items: center;
  margin-right: 65px;
  background-color: white;
  color: black;
  padding: 5px 10px !important;
  border: 1px solid gray;
  margin-top: 15px;
}

@media print {
    #tablePrint * {
        /* visibility: visible;
        top: -20px;
        margin-top: 0px;
        border: 0px solid transparent; */
        visibility: visible;
      border: 0px solid transparent;
      width: 100px;
      margin-top: 10px;
    }

    .TableSection{
        height: max-content;
        width:max-content;
        margin-left: 40px;
      }
    .btn-ab , .view-title{
        display: none;
    }
    .mycontent-right{
        width: 900px;
    }
}
.span-field {
    background-color: rgba(128, 128, 128, 0.08);
    padding: 5px 20px;
    cursor: pointer
}
.view-btn,.view-btn:hover{
    border: 1px solid #dcbb68 !important;
    color: #fff !important;
}

.timeslott {
    display: 'block';
    height: '30px';
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    align-content: flex-start;
    color: rgba(0, 0, 0, 0.65);
    font-size: 8px !important;
    font-variant: tabular-nums;
    align-content: left;
    line-height: 3.5 !important;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    margin-right: 8px;
    white-space: nowrap;
    cursor: pointer;
    height: 1em;
    width: 60%;
    -ms-transform: scale(1.5);
    padding-left:50px ;
    -webkit-transform: scale(1.5);
    transform: scale(1) !important;
  }
  .ant-radio-input{

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
  }

  .mycontent-right2{
    text-align: right !important;
    background: transparent  !important;
    border-radius: 0 0 4px 4px;
    margin-left: 90px;
    padding-top: 10px;
  }
  .reqTime{
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 30px;
    padding-bottom: 20px;
  }
  .ant-modal-footer{
    margin-right: 20px;
    border-top: 1px solid transparent !important;
  }
  .heading-Req{
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .delivery{
    font-size: 14px !important;
    font-weight: bolder !important;
  }