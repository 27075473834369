@media print{
    body * {
        visibility: hidden;
        margin-top: -10px;
      }
    #TipTable *{
        visibility: visible;
        
    }
    #TipTableSummary *{
        visibility: visible;
        margin-top: -10px;
        
    }

}