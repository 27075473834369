.dailyTable , .dailyHeader , .dailyRow , .dailyMainData , .dailyData {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.65);
    width: 700px;
    /* height: 200px; */
}

.dailyHeader{
    padding-left: 20px;
    color: gray;
    opacity: .7;
    letter-spacing: .8px !important;
    font-size: 13px !important;
    font-weight: 600 !important;
} 


.dailyRow{
    border: 1px solid rgba(0, 0, 0, 0.65);
    height: 30px;
}

.dailyMainData{
    padding-left: 20px;
    color: rgb(240, 41, 41);
    font-weight: 600 !important;
}
.dailyData {
    text-align: left;
    font-size: 12px !important;
    color: rgb(49, 48, 48);
    font-weight: 600 !important;
    padding-left: 20px;
}

.report-heading{
    font-size: 14px !important;
    color:rgba(58, 56, 56, 0.98);
    font-weight: 700 !important;

}
.report-div{
    margin-top: 30px !important;
    align-items: center !important;
    justify-content: center !important;
}
.report-time{
    margin-top: 10px !important;
    align-items: center !important;
    justify-content: center !important;

}
.venName{
    color:rgba(58, 56, 56, 0.98);
    font-size: 13px !important;
    padding-top: 10px;
}
.noData{
    color: gray;
    font-size: 16px !important;        
}
.noDeposit{
    color: black;
}
.dailyTotalData{
    color: #A52A2A;
    text-align: left;
    font-size: 12px !important;
    padding-left: 20px;
    font-weight: 600 !important;
}   
@media print {
    .dailyTablePrint * {
      visibility: visible;
      /* border: 0px solid transparent;
      width: 800px;
      margin-top: 30px;
      text-align: center; */
        
    }
    .report-container{
        height: max-content;
    }
    .report-heading{
        visibility: visible;
        font-size: 14px !important;
        color:rgba(58, 56, 56, 0.98);
        font-weight: 700 !important;  
    }
    .dailyHeader{
        visibility: visible;
        padding-left: 20px;
        color: gray;
        opacity: .7;
        letter-spacing: .8px !important;
        font-size: 14px !important;
        font-weight: 600 !important;
    } 
  
    #dailyTablePrint{
    visibility: visible;
    height: max-content;
    /* height: 1200px; */
    width: 800px;
    }
    .dailyMainData{
        visibility: visible;
        padding-left: 20px;
        color: rgb(240, 41, 41);
    }
    .dailyData {
        visibility: visible;
        text-align: left;
        font-size: 14px;
        color:rgba(58, 56, 56, 0.98) !important;
        padding-left: 20px;
    }
    .report-div{
        visibility: visible;
        margin-top: 30px !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .report-time{
        visibility: visible;
        margin-top: 10px !important;
        align-items: center !important;
        justify-content: center !important;
    
    }
    .venName{
        visibility: visible;
        color:rgba(58, 56, 56, 0.98);
        font-size: 13px !important;
        padding-top: 20px;
    }
    .noData{
        visibility: visible;
        color: gray;
        font-size: 16px !important;        
    }
    .dailyTotalData{
        visibility: visible;
        color: #A52A2A;
        text-align: left;
        font-size: 12px !important;
        padding-left: 20px;
        font-weight: 600 !important;
    }   
    
  
   
}