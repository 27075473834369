.btn-ab ,.btn-ab:hover , .btn-ab:focus , .btn-ab:active{
    background-color: #d8bb14 !important;
    border: 1px solid #dcbb68 !important;
    color: #fff !important;
}
@media print {
    .tablePrint * {
        visibility: visible;
        top: 0px;
        border: 0px solid transparent;
        
    }
}