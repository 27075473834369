.box-t {
  outline: none;
  border: none;
  padding: 20px 32px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px;
  height: 70px;
  min-width: 180px;
}
#flex {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 400px;
  margin-bottom: 30px;
}

.box {
  outline: none;
  border: none;
  padding: 20px 32px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px;
  height: 70px;
  width: 250px;
}
@media (max-width: 1200px) {
  .different-reports {
    flex-direction: column;
    align-items: center;
  }
  /* .report-container {
    max-width: 90%;
    margin: 20px auto;
  } */
}
.different-reports-t {
  width: 90%;
  margin-top: 5vh;
  margin-right: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  min-width: 290px;
}
.grp .ant-btn:focus {
  color: #fff;
  background: #333;
  border-color: #333;
}
@media (max-width: 380px) {
  .report-container-flex {
    margin-top: 10%;
    margin-left: 23px;
  }
  .different-reports-t {
    margin-left: 20px;
  }
}
@media (max-width: 414px) {
  .report-container-flex {
    margin-top: 0;
    margin-left: 20px;
  }
  .different-reports-t {
    margin-left: 20px;
  }
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  border-bottom: 1px solid silver;
  font-size: 13px;
}

@media print {
  body * {
    visibility: hidden;
    margin-top: -25px;
  }
  .ant-pagination * {
    visibility: hidden;
    display: none;
  }
  .report-container * {
    visibility: hidden;
    display: none;
  }
  #printgroup * {
    visibility: visible;
    top: 0px;
    margin-top: 0px;
  }
  #printgroup {
    left: 0;
  }
  #printgroup .printtiptitle {
    visibility: visible;
  }
  #printbtn {
    display: none;
  }
  #counters {
    visibility: visible;
  }
}
