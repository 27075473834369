#container {
  margin: 0;
}

.ant-table-body .ant-table-footer {
  padding: 0;
  overflow-x: hidden !important;
}

.highlighted-bg td {
  background-color: #e5f7ff !important;
  font-weight: 700 !important;
}