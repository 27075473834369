.react-bs-table-container {
  width: 100%;
  margin-top: 16%;
}

.table {
  display: inline-table;
}

.table-header-wrapper {
  background-color: #ffa733;
  color: white;
}

@media (max-width: 414px) {
  .react-bs-table-container {
    width: 100%;
    margin-top: 0%;
    font-size: 10px;
  }
  .table {
    display: inline-table;
  }
}
