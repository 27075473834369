.res-profile-wrapper {
  border: 1px solid #f5f5f5;
  position: relative;
  margin: 80px 0;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  background: #fefefe;
  align-content: stretch;
}

.fa-pencil {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  cursor: pointer;
  color: #222;
}

.my-modal h4 {
  color: white;
  text-align: left;
  width: 100%;
  margin: 10px 0 0 60px;
}

.small-textarea {
  font-size: 10px;
  border: 1px solid #333 !important;
}

.my-modal .input2 {
  margin: 7px 0;
}

.res-flex {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
}

.image-round-profile {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-right: 1px solid black;
  margin: auto;
}

.flex-2 {
  flex-grow: 5;
  padding: 5px 5px 5px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.res-flex.title {
  border-bottom: 1px solid #f4f4f4;
  padding: 0 0 20px 0;
}

.info-hotel-mini {
  margin: 2em 2.5em 1em 2.5em;
}

.info-hotel-mini .hotel-info {
  line-height: 1.625;
  display: flex;
  font-size: 1.15em;
  padding: 0.35em 0;
  border-bottom: 1px solid #f4f4f4;
  margin: 0.35em 0;
}

.info-hotel-mini .hotel-info.description {
  border-bottom: none !important;
  margin: 4em 0;
}

.info-hotel-mini .hotel-info.description h4 {
  color: #444 !important;
}

.info-hotel-mini .hotel-info span {
  min-width: 150px;
}

.flex-1 {
  flex-grow: 0;
}

.flex-3 {
  width: 100%;
}

.round {
  padding: 5px 5px 5px 5px;
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: space-around;
  border-radius: 50%;
  box-shadow: 1px 5px 5px #888888;
}

#hotel-name {
  font-size: 32px;
}

.profile-data {
  display: flex;
  align-items: center;
  min-height: 100px;
  background: #e3e3e3;
  align-self: center;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
  border-bottom: 1px solid #b1afaf;
  padding: 5px 15px 5px 15px;
}

.lower-flex-profile {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.add-restaurant {
  background: #f1f1f1 !important;
  border: 1px solid #d3d3d3 !important;
  padding: 20px 0;
  justify-content: normal;
  height: auto !important;
}

.add-restaurant li input,
.add-restaurant li textarea {
  border: 1px solid #d3d3d3 !important;
}

.add-restaurant li {
  padding: 5px;
}

.ant-avatar > img {
  height: auto !important;
}

.res {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-align: center;
  align-items: center;
  min-width: 50%;
  min-height: 70vh;
}

.form1 {
  height: 400px;
  width: 400px;
  border-radius: 4px;
  background-color: #ffa733;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -6px black;
  padding-bottom: 20px;
}

li {
  list-style: none;
}

.input1 {
  padding-left: 10px;
  width: 300px;
  padding: 5px 10px;
  margin: 5px 0;
  border: none;
  border-bottom: 1px solid #8e8a85;
  font-size: 14px;
  outline: none;
  color: black;
  border-radius: 4px;
  background-color: white;
}

.input1:focus {
  border-bottom: 2px solid black;
}

#submit-landing1 {
  background-color: #424244;
  text-transform: uppercase;
  padding: 5px 15px 5px 15px;
  border-radius: 4px;
  border: 1px solid white;
  min-width: 200px;
  color: white;
}

textarea {
  resize: none;
}

@media (max-width: 1100px) {
  .res-profile-wrapper {
    max-width: 90%;
    margin: 40px auto;
  }
  .ant-table-wrapper,
  .ant-form {
    max-width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 1024px) {
  .res {
    margin-top: -30%;
  }
}

@media (max-width: 414px) {
  .res-profile-wrapper {
    border: 1px solid #f5f5f5;
    width: 120%;
    margin-top: 50px;
    margin-left: -3px;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    align-content: stretch;
  }
  .res {
    margin-top: -15%;
  }
  .flex-2 p {
    margin: 23px;
  }
  .lower-flex-profile {
    flex-direction: column;
  }
  .profile-data {
    width: 100%;
  }
  #hotel-name {
    font-size: 40px;
  }
}


@media (max-width: 768px) {
  .content {
    width: 100%;
    margin: 0;
  }
}

@media (max-width: 900px) {
  .lower-flex-profile {
    flex-direction: column;
  }
  .profile-data {
    width: 100%;
  }
  #hotel-name {
    font-size: 40px;
  }
}

@media (max-width: 700px) {
  #hotel-name {
    font-size: 30px;
  }
}

.profile-data-sub {
  align-self: center;
}
