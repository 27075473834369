.login-body {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: linear-gradient(45deg, #385777, #113156); */
  /* padding: 50px; */
  /* letter-spacing: 1px; */
  /* font-family: "Ubuntu", sans-serif; */
  height: 100vh;
}
.productImg .img-fluid {
  width: 100%;
  height: 100%;
  padding: 0;
}
.left-login {
  padding: 0px !important;
}
.login-main {
  /* background-color: #07182c; */
  background-image: linear-gradient(45deg, #1b3045, #07182c);
  height: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 15px 15px; */
  overflow: hidden;
  padding: 0px 0px !important;
  border-bottom-left-radius: 2.5rem;
  border-top-left-radius: 2.5rem;
  border-radius: 5px;
  border: 2px solid #B59B56;
}
.login-main form {
  padding: 1rem 8rem 5rem;
}
.imgcontainer h3 {
  font-size: 16px;
  color: #fff;
  /* margin: 5px 0 10px; */
  font-weight: 300;
}

.machine {
  padding: 0px !important;
}
.mac-pad {
  padding: 30px 0;
}
.a-logo img {
  width: 65%;
}

.a-board {
  padding: 20px 25px;
}
.sign {
  padding-top: 40px;
  color: #1b3045;
  font-weight: bold;
  font-size: 23px;
}
/* .log-col {
  filter: drop-shadow(6px 15px 10px #18191a);
  overflow: hidden;
  padding: 0px 0px !important;
  background-color: #07182c;
  border-bottom-left-radius: 2.5rem;
  border-top-left-radius: 2.5rem;
  border-radius: 10px;
  border: 2px solid #a8822f;
} */
.login-main input {
  padding: 5px 20px;
  /* margin: 10px 0; */
  display: inline-block;
  box-sizing: border-box;
}
input {
  border: none;
}
.lgn-Btn {
  border-radius: 53px !important;
  background-color: #a8822f;
  color: white;
  padding: 14px 20px;
  border: none;
  cursor: pointer;
  width: 60%;
  font-size: 17px !important;
  margin: 25 auto !important;
  display: block !important;
}

.lgn-Btn:hover {
  opacity: 0.8;
}
.imgcontainer {
  padding: 5rem 0 0;
  text-align: center;
  /* margin: 14px 0 0; */
}

img.avatar {
  width: 15%;
  /* border-radius: 50%; */
  margin: 0 auto 15px ;
  /* display: block; */
}

span.psw {
  font-size: 14px;
  float: right;
}
span.psw a {
  color: #fff;
}
.checkbox {
  color: #1b3045;
}
label {
  font-size: 14px;
  color: #fff;
}
.bottom-login {
  margin-top: 5px;
}
.login-form .ant-form-item {
  margin-bottom: 0 !important;
}
.login-form .ant-form-item .ant-input {
  height: 40px;
  font-size: 14px !important;
}
.login-form-forgot{
  color: #fff !important;
  font-size: 14px !important;
}
.login-form .ant-form-item .ant-form-item-children .ant-checkbox-wrapper {
  color: #fff;
}
.dFlax .ant-form-item-children {
  display: flex;
}
.loginIn-btn {
  width: 100%;
  height: 40px;
  font-size: 16px !important;
  background-color: #B59B56 !important;
  border: 1px solid #B59B56 !important;
}
.mb-2r{
  margin-bottom: 2rem;
}
.isError input {
  border: 2px solid #ff0000;
}
.login-error-msg{
  line-height: 0.8;
  margin: 0;
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .login-main form {
    margin-right: 0px;
    padding: 48px 23px 57px 0px;
  }
  .a-logo img {
    width: 100%;
  }
  .a-board {
    padding: 20px 15px;
  }
  .mac-pad {
    margin-top: 50px;
  }
}
/* @media screen and (min-device-width: 640px) and (max-device-width: 990px) {
  .productImg .img-fluid {
    width: 100%;
    max-height: 100%;
    padding: 0;
    height: 56%;
  }
  .login-body {
    padding: 102px 50px 50px;
  }
} */
@media screen and (min-device-width: 991px) and (max-device-width: 1024px) {
  .productImg .img-fluid {
    height: 80%;
    margin-top: 52px;
  }
  /* .login-body {
    padding: 0px 50px 50px;
  } */
  /* .login-body { */
    /* display: table;
    margin-top: 120px;
  } */
}
