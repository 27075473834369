.categoryListTable .ant-table table {
  table-layout: fixed;
}
.catForm .ant-form-item-control-wrapper {
  display: block !important;
}
.ant-form input {
  border: 1px solid #ffd700;
}
.ant-form input:hover {
  border: 1px solid #dcbb68;
}
.ant-form .ant-select-selection {
  border: 1px solid #ffd700;
}
.ant-form .ant-select-selection:hover {
  border: 1px solid #dcbb68;
}
