.productCard .ant-card-body{
    width: 100%;
}
.cartProductCard .ant-card-body{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.productCard .ant-card-meta-title {
    font-size: 14px;
    white-space: wrap;
}

.totalBillSummary .ant-descriptions-item{
    display:flex;
    justify-content: space-between;
}
.billDisplay,.buttonDisplay{
    background-color: #F8F8F8;
    padding: 10px;
    border-radius: 8px;
}
.buttonDisplay button{
    background-color: #fff;
    text-wrap: nowrap;
}
.cartProDisplay{
    height: 430px;
    overflow-y: scroll;
}